import * as React from 'react';
import { getIntegrations } from '../services/integrations';

/**
 * Main Page
 * @export
 * @return {*}  {JSX.Element}
 */
export function Main(): JSX.Element {
  console.log('hello moto');
  getIntegrations().then(console.log).catch(console.error);
  return <div>Hello</div>;
}
