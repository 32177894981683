import React from 'react';
import './App.css';
import { RecoilRoot } from 'recoil';
import { Main } from './components/Main';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { COGNITO, MAIN_API } from './configs/aws';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  API: {
    endpoints: [
      {
        ...MAIN_API,
        custom_header: async () => {
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

/**
 * App Entry Point
 * @param {*} { user }
 * @return {*}  {JSX.Element}
 */
function App({ user }: any): JSX.Element {
  console.log(`user: ${user.username}`);
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </Router>
    </RecoilRoot>
  );
}
export default withAuthenticator(App);
