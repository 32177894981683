import { API } from 'aws-amplify';
import { MAIN_API } from '../configs/aws';

const apiName = MAIN_API.name;

export const asyncGet = (path: string) => {
  return new Promise((resolve, reject) => {
    API.get(apiName, `/api/${path}`, {}).then(resolve).catch(reject);
  });
};

export const asyncPost = (path: string, body: any) => {
  return new Promise((resolve, reject) => {
    API.post(apiName, `/api/${path}`, { body: body })
      .then(resolve)
      .catch(reject);
  });
};
